/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider {
	margin-bottom: 0px !important;
	.slick-slide{
		position: relative;
		height: calc(100vh - 230px);
		>img{
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
		@media screen and (max-width: 990px){
			height: calc(100vh - 80px);
		}
	}
	.request{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 50%;
		margin-right: -570px;
		background-color: rgba(0,0,0,0.7);
		padding: 80px 50px 40px 49px;
		h3{
			font-size: 36px;
			text-align: center;
			text-transform: uppercase;
			color: #fff;
		}
		h5{
			font-size: 30px;
			text-align: center;
			color: #fff;
			margin-bottom: 35px;
		}
		form{
			width: 470px;
			border-top: 1px solid #c2c5c8;
			h3{
				margin: 15px 0 23px 0;
			}
			.one-line{
				display: flex;
				input{
					margin: 4px 7px 4px 7px;
				}
			}
			input[type="text"],
			input[type="email"] {
				width: calc(50% - 14px);
			}
			.btn{
				padding: 15px 61px;
			}
		}
		p{
			color: #fff;
			text-align: center;
			font-size: 16px;
			margin: 18px 0 0 0;
		}
		@media screen and (max-width: 1690px){
			padding: 50px 30px 20px 30px;
			h3{
				font-size: 30px;
			}
			h5{
				font-size: 26px;
			}
		}
		@media screen and (max-width: 1370px){
			padding: 40px 20px 20px 20px;
			margin-right: -520px;
			h3{
				font-size: 26px;
			}
			h5{
				font-size: 24px;
			}
		}
		@media screen and (max-width: 1125px){
			padding: 30px 15px 20px 15px;
			margin-right: -470px;
			h3{
				font-size: 24px;
			}
			h5{
				font-size: 22px;
			}
		}
		@media screen and (max-width: 990px){
			margin: 0 auto;
			left: 0;
			right: 0;
			width: 51%;
			form{
				width: 100%;
			}
			h3{
				font-size: 22px;
			}
			h5{
				font-size: 20px;
			}
		}
		@media screen and (max-width: 768px){
			width: 80%;
			padding: 30px 25px 20px 15px;
			margin: 15px auto 30px auto;
			form{
				.one-line{
					display: block;
					input[type="text"],
					input[type="email"]{
						width: 100%;
						padding: 7px 10px;
					}
				}
				.btn{
					padding: 12px 35px;
				}
				h3{
					margin: 10px 0;
				}
			}
			h3{
				font-size: 18px;
			}
			h5{
				font-size: 16px;
				margin-bottom: 5px;
			}
		}
		@media screen and (max-width: 540px){
			width: 90%;
			padding: 10px 25px 10px 15px;
			margin: 5px auto 30px auto;
			form{
				.one-line{
					display: block;
					input[type="text"],
					input[type="email"]{
						padding: 5px 10px;
					}
				}
				.btn{
					padding: 8px 30px;
				}
				h3{
					font-size: 16px;
					margin: 5px 0;
				}
			}
			p{
				font-size: 12px;
				margin: 5px 0 0 0;
			}
			h3{
				font-size: 16px;
			}
			h5{
				font-size: 14px;
				margin-bottom: 5px;
			}
		}
	}
}
.social-fixed{
	position: fixed;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	@include ul-default;
	z-index: 9;
	>li{
		height: 60px;
		position: relative;
		// transform: translateX(155px);
		margin-bottom: 8px;
		transition: 300ms;
		>a{
			display: flex;
			align-items: center;
			justify-content: flex-start;
			text-align: left;
			width: 220px;
			height: 100%;
			border-radius: 30px;
			padding: 0 30px 0 70px;
			background-color: #ff0000;
			color: #fff;
			transition: 350ms;
			line-height: 1;
			i{
				color: #fff;
				font-size: 25px;
				position: absolute;
				top: 50%;
				left: 25px;
				transform: translateY(-50%);
			}
			span{
				color: #fff;
				margin-left: 18px;
				line-height: 1;
			}
			&:hover{
				background-color: #34aa00;
			}
		}
		&:hover {
			transform: translateX(0px);
		}
	}
	@media screen and (max-width: 990px){
		>li{
			transform: translateX(68px);
			height: 30px;
			font-size: 9px;
			>a{
				width: 100px;
				padding: 0 15px 0 33px;
				i{
					font-size: 16px;
					left: 10px;
				}
			}
			&:hover{
				transform: translateX(68px);
			}
		}
	}
	@media screen and (min-width: 1024px){
		>li{
			transform: translateX(155px);
		}
	}
}
/* main slider */

/* main content */
.advantages-1{
	.container{
		display: flex;
		flex-wrap: wrap;
		padding: 40px 15px;
		position: relative;
		&:after{
			content: '';
			position: absolute;
			bottom: 0;
			right: 15px;
			left: 15px;
			top: 0;
			border-bottom: 1px solid #d7d7d7;
		}
	}
	.adv__block{
		width: calc(33.3% - 30px);
		padding: 40px 15px 18px 15px;
		margin: 0 15px;
		background-color: #f7f7f7;
		display: flex;
		flex-direction: column;
		align-items: center;
		border-bottom: 9px solid transparent;
		z-index: 1;
		.img{
			>img{
				width: 50px;
				height: 50px;
				filter: grayscale(100%) brightness(45%) contrast(2);
				transition: 300ms;
			}
		}
		h3{
			font-size: 24px;
			text-transform: uppercase;
			text-align: center;
			transition: 300ms;
			font-family: $medium;
		}
		p{
			transition: 300ms;
			text-align: center;
		}
		&:hover{
			background-color: #fff;
			box-shadow: 7px 10px  10px rgba(0,0,0,0.2);
			border-bottom: 9px solid #faa643;
			.img{
				>img{
					filter: grayscale(0);
				}
			}
			h3{
				color: #faa643;
			}
			p{
				color: #faa643;
			}
		}
	}
	@media screen and (max-width: 1100px){
		.adv__block{
			h3{
				font-size: 20px;
			}
		}
	}
	@media screen and (max-width: 990px){
		.container{
			padding: 25px 15px;
		}
		.adv__block{
			h3{
				font-size: 18px;
			}
		}
	}
	@media screen and (max-width: 880px){
		.adv__block{
			width: calc(33.3% - 15px);
			margin: 7.5px;
		}
	}
	@media screen and (max-width: 800px){
		.adv__block{
			width: calc(50% - 15px);
		}
	}
	@media screen and (max-width: 768px){
		.container{
			padding: 20px;
		}
		.adv__block{
			width: 100%;
			h3{
				font-size: 16px;
			}
		}
	}
}

.advantages-2{
	padding: 25px 0 100px 0;
	.container{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.adv__block{
		width: calc(50% - 15px);
		background-color: #f7f7f7;
		display: flex;
		align-items: center;
		padding: 30px 15px 30px 55px;
		margin: 15px 0;
		border-right: 9px solid transparent;
		.img{
			position: relative;
			width: 50px;
			height: 50px;
			>img{
				width: 100%;
				height: 100%;
				object-fit: contain;
				object-position: center center;
				filter: grayscale(100%) brightness(45%) contrast(2);
				transition: 300ms;
				margin: auto;
			}
			&:after{
				content: '';
				width: 100px;
				height: 95px;
				border: 2px solid #252525;
				border-radius: 100%;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 0;
				left: 50%;
				margin-left: -52px;
				bottom: 0;
				transition: 300ms;
			}
		}
		.text{
			margin-left: 75px;
			h3{
				font-size: 24px;
				text-transform: uppercase;
				transition: 300ms;
				font-family: $medium;
			}
		}
		&:hover{
			background-color: #fff;
			box-shadow: 2px 5px 20px rgba(0,0,0,0.2);
			border-right: 9px solid #faa643;
			.img{
				>img{
					filter: grayscale(0);
				}
				&:after{
					border: 2px solid #faa643;
				}
			}
			h3{
				color: #faa643;
			}
			p{
				color: #faa643;
			}
		}
	}
	@media screen and (max-width: 1100px){
		padding: 20px 0 45px 0;
		.adv__block{
			.text{
				h3{
					font-size: 20px;
				}
			}
		}
	}
	@media screen and (max-width: 990px){
		.adv__block{
			.text{
				h3{
					font-size: 18px;
				}
			}
		}
	}
	@media screen and (max-width: 880px){
		.adv__block{
			width: calc(50% - 15px);
			margin: 7.5px;
		}
	}
	@media screen and (max-width: 800px){
		.adv__block{
			display: block;
			padding: 20px;
			.img{
				margin: 20px auto;
			}
			.text{
				margin: 40px auto 0 auto;
				text-align: center;
			}
		}
	}
	@media screen and (max-width: 768px){
		padding: 20px 0 25px 0;
		.adv__block{
			width: 100%;
			.text{
				h3{
					font-size: 16px;
				}
			}
		}
	}
}

.about{
	display: flex;
	.left{
		width: 50%;
		background-color: #f7f7f7;
		position: relative;
		padding: 180px 175px 175px 365px;
		position: relative;
		z-index: 1;
		&:after{
			content: '';
			position: absolute;
			top: 0;
			right: 20px;
			left: 0;
			bottom: -130px;
			background: url(../img/about-bg.png) no-repeat center center;
			background-size: contain;
			z-index: -1;
		}
		>img{
			position: absolute;
			left: 0;
			bottom: 0;
		}
	}
	.right{
		width: 50%;
		>img{
			@include cover;
		}
	}
	@media screen and (max-width: 1695px){
		.left{
			padding: 80px 180px;
		}
	}
	@media screen and (max-width: 1325px){
		.left{
			padding: 70px 80px; 
		}
	}
	@media screen and (max-width: 1200px){
		.left{
			padding: 60px;
		}
	}
	@media screen and (max-width: 768px){
		display: block;
		padding: 0 15px;
		.left{
			width: 100%;
			padding: 40px;
			&:after{
				bottom: 0;
				right: 0;
				left: 0;
			}
		}
		.right{
			width: 100%;
			height: 300px;
		}
	}
	@media screen and (max-width: 400px){
		.left{
			padding: 20px 15px;
		}
		.right{
			height: 250px;
		}
	}
}

.services{
	padding: 115px 0;
	overflow: hidden;
	.services-list{
		.services_block{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin: 0 0 75px 0;
			.services-img{
				width: calc(50% - 85px);
				position: relative;
				height: 600px;
				z-index: 1;
				>img{
					@include cover;
				}
				&:nth-of-type(odd){
					&:before{
						content: '';
						position: absolute;
						left: -77%;
						top: 130px;
						bottom: 0;
						right: 50px;
						background-color: #f1f1f1;
						z-index: -1;
					}
				}
				&:nth-of-type(even){
					&:before{
						content: '';
						position: absolute;
						right: -77%;
						top: 65px;
						bottom: 65px;
						left: 50px;
						background-color: #f1f1f1;
						z-index: -1;
					}
				}
				&:last-child{
					&:before{
						top: 0;
						bottom: 130px;
					}
				}
			}
			.text{
				width: calc(50% - 85px);
				padding: 65px 0 40px 0;
				.btn{
					padding: 15px 70px;
					margin-top: 40px;
				}
			}
		}
	}
	@media screen and (max-width: 1640px){
		.services-list{
			.services_block{
				.services-img{
					width: calc(50% - 35px);
				}
				.text{
					width: calc(50% - 35px);
				}
			}
		}
	}
	@media screen and (max-width: 1515px){
		.services-list{
			.services_block{
				.services-img{
					.serv-img-1{
						width: 100%;
					}
				}
			}
		}
	}
	@media screen and (max-width: 1300px){
		padding: 80px 0;
	}
	@media screen and (max-width: 1200px){
		padding: 50px 0;
	}
	@media screen and (max-width: 1100px){
		padding: 40px 0;
	}
	@media screen and (max-width: 990px){
		padding: 30px 0;
	}
	@media screen and (max-width: 768px){
		padding: 20px 0;
		.services-list{
			.services_block{
				display: block;
				margin: 0 0 20px 0;
				.services-img{
					width: 100%;
					height: 300px;
					>img{
						@include contain;
					}
					&:nth-of-type(odd){
						&:before{
							content: '';
							right: 0;
							left: 0;
							bottom: 0;
							top: 0;
						}
					}
					&:nth-of-type(even){
						&:before{
							right: 0;
							left: 0;
							bottom: 0;
							top: 0;
						}
					}
				}
				.text{
					width: 100%;
					padding: 30px 0;
					margin: 20px 0;
				}
			}
		}
	}
	@media screen and (max-width: 540px){
		.services-list{
			.services_block{
				.text{
					padding: 15px 0;
					.btn{
						padding: 10px 30px;
						margin-top: 25px;
					}
				}
			}
		}
	}
}

.consult{
	background-color: #f0f0f0;
	position: relative;
	.left-bg{
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		>img{
			width: 100%;
			height: 100%;	
		}
	}
	.right-bg{
		width: 30%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		>img{
			@include cover;
			filter: grayscale(1);
		}
		&:after{
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background-color: rgba(250,166,67,0.8);
		}
	}
	.girl{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		height: 100%;
	}
	.container{
		padding: 85px 15px 100px 15px;
		position: relative;
	}
	.form{
		padding: 0 30px;
		position: relative;
		z-index: 1;
		display: table;
		h3{
			font-size: 36px;
			text-transform: uppercase;
			padding: 0 25px;
			background-color: #f0f0f0;
			display: table;
		}
		span{
			font-size: 26px;
			font-family: $bold;
			padding-left: 25px;
		}
		&:after{
			content: '';
			position: absolute;
			top: 17px;
			right: 0;
			left: 0;
			bottom: 20px;
			border: 1px solid #a7a7a7;
			z-index: -1;
		}
		form{
			width: 350px;
			margin-left: 25px;
			p{
				margin: 20px 0;
			}
			.btn-bg{
				padding-left: 25px;
				padding-right: 25px;
				background-color: #f0f0f0;
				display: table;
				.btn{
					padding: 13px 40px;
					margin-top: 40px;
				}
			}
		}
	}
	@media screen and (max-width: 910px){
		.girl{
			height: 300px;
			right: 15px;
			top: 50%;
			transform: translateY(-50%);
		}
		.container{
			padding: 45px 15px;
		}
	}
	@media screen and (max-width: 768px){
		.girl{
			display: none;
		}
		.right-bg{
			position: relative;
			width: 100%;
			height: 200px;
		}
		.form{
			form{
				width: 100%;
				margin-left: 0;
			}
			h3{
				font-size: 30px;
			}
			span{
				font-size: 22px;
			}
		}
	}
	@media screen and (max-width: 500px){
		.form{
			padding: 0 20px;
			form{
				.btn-bg{
					padding: 0;
					.btn{
						margin-top: 20px;
						padding: 10px 20px;
					}
				}
				p{
					font-size: 14px;
					margin: 10px 0;
				}
			}
			h3{
				font-size: 24px;
			}
			span{
				font-size: 18px;
				padding-left: 0;
			}
		}
	}
}

.reviews{
	padding: 110px 0 55px 0;
	h2{
		margin: 0;
	}
	.reviews-slider{
		margin: 0 auto;
		.slick-list{
			.slick-slide{
				background-color: #fff;
				background-color: #fff;
				box-shadow: 5px 5px 20px rgba(0,0,0,0.5);
				margin: 90px 0;	
				transform: scale(0.7)  translate(150%, 5%);
				transition-duration: 500ms;
				position: relative;
				z-index: -1;
				outline: none;
				cursor: pointer;
				&:after {
					content:'';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background: #fff;
					transition: 300ms;
					opacity: 0.8;
				}
				.review-video{
					height: 240px;
					position: relative;
					>img{
						@include cover;
						filter: grayscale(1);
					}
					>iframe{
						position: absolute;
						top: 0;
						right: 0;
						left: 0;
						bottom: 0;
						width: 100%;
						height: 100%;
					}
					.play-btn{
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						right: 0;
						left: 0;
						width: 100px;
						height: 100px;
						background: url(../img/play.png) no-repeat center center;
						background-size: contain;
						margin: 0 auto;
					}
				}
				.text{
					padding: 30px;
					border-bottom: 6px solid transparent;
					text-align: center;
					h4{
						font-size: 26px;
					}
				}
				&.slick-center{
					transform: scale(1.2);
					opacity: 1;
					.review-video{
						>img{
							filter: grayscale(0);
						}
					}
					.text{
						border-bottom: 6px solid #faa643;
						z-index: 1;
					}
				}
			}
		}
		.slick-track {
			.slick-slide.slick-active {
				transform: scale(0.8) translate(50%, 5%);
				z-index: 0;
				&:after {
					opacity: 0.5;
				}
			}
			.slick-slide.slick-center {
				transform: scale(1) !important;
				z-index: 1;
				&:after {
					opacity: 0;
				}
			}
			.slick-slide.slick-center + .slick-slide {
				transform: scale(0.8) translate(-50%, 5%);
			}
			.slick-slide.slick-center + .slick-slide + .slick-slide {
				transform: scale(0.7) translate(-150%, 5%);
			}
		}
		.slick-arrow{
			width: 65px;
			height: 60px;	
			background-color: rgba(0,0,0,0.3);
			z-index: 1;
			&:before{
				display: none;
			}
			&:after{
				content: "\f105";
				color: #fff;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				font-family: 'FontAwesome';
				font-size: 35px;
				right: 0;
				left: 0;
				margin: 0 auto;
			}
		}
		.slick-next{
			right: 120px;
		}
		.slick-prev{
			left: 120px;
			&:after{
				transform: translateY(-50%) rotate(180deg);
			}
		}
	}
	@media screen and (max-width: 1300px){
		padding: 80px 0;
	}
	@media screen and (max-width: 1200px){
		padding: 50px 0;
	}
	@media screen and (max-width: 1100px){
		padding: 40px 0;
		.reviews-slider .slick-list .slick-slide{
			transform: scale(.7) translate(135%, 5%);
		}
	}
	@media screen and (max-width: 990px){
		padding: 30px 0;
		.reviews-slider {
			.slick-track {
				.slick-slide.slick-center +.slick-slide{
					transform: scale(.8) translate(-2%, 5%);
				}
				.slick-slide{
					margin: 50px 0;
					.review-video{
						.play-btn{
							width: 60px;
							height: 60px;
						}
					}
					.text{
						h4{
							font-size: 24px;
						}
					}
				}
			}
			.slick-next{
				right: 25px;
			}
			.slick-prev{
				left: 25px;
			}
		}
	}
	@media screen and (max-width: 768px){
		padding: 20px 0;
		.reviews-slider{
			.slick-track{
				.slick-slide.slick-center +.slick-slide{
					transform: scale(.8) translate(15%, 5%);
				}
				.slick-slide.slick-center+.slick-slide+.slick-slide{
					transform: scale(.8) translate(-112%, 5%);
				}
				.slick-slide{
					margin: 25px 0;
					.review-video{
						.play-btn{
							width: 50px;
							height: 50px;
						}
					}
					.text{
						padding: 15px;
						h4{
							font-size: 22px;
						}
					}
				}
			} 
			.slick-arrow{
				width: 35px;
				height: 35px;
				&:after{
					font-size: 20px;
				}
			}
		}
	}
	@media screen and (max-width: 400px){
		.reviews-slider{
			.slick-list .slick-slide{
				transform: scale(.7) translate(-55%,5%);
			}
			.slick-track{
				.slick-slide.slick-center +.slick-slide{
					transform: scale(.8) translate(35%, 5%);
				}
				.slick-slide.slick-center+.slick-slide+.slick-slide{
					transform: scale(.8) translate(-83%, 5%);
				}
				.slick-slide{
					.text{
						padding: 15px;
						h4{
							font-size: 20px;
						}
					}
				}
			}
		} 
	}
}

.contacts{
	position: relative;
	.container{
		padding: 0;
	}
	#map{
		width: 100%;
		height: 430px;
		>ymaps{
			width: 100% !important;
			height: 100% !important;
		}
	}
	.hts{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		@include ul-default;
		padding: 15px;
		background-color: rgba(0,0,0,0.3);
		&:after{
			content: '';
			position: absolute;
			top: 50%;
			right: -11px;
			border-top: 74px solid rgba(0,0,0,.3);
			border-left: 74px solid transparent;
			transform: rotate(45deg) translateY(-50%);
		}
		li{
			padding: 30px;
			background-color: #fff;
			margin-bottom: 8px;
			padding-left: 100px;
			position: relative;
			&:before{
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 21px;
				bottom: 0;
				width: 60px;
				height: 60px;
				background-color: #faa643;
			}
			&:after{
				content: '';
				font-family: 'FontAwesome';
				left: 42px;
				top: 50%;
				transform: translateY(-50%);
				bottom: 0;
				position: absolute;
				color: #fff;
				font-size: 26px;
				display: table;
			}
			&.address{
				&:after{
					content: '\f041';
				}		
			}
			&.phone{
				&:after{
					content: '\f095';
				}
				a{
					display: block;
					.show-num{
						color: #faa643;
					}
				}
			}
			&.email{
				&:after{
					content: '\f0e0';
					font-size: 24px;
				}
			}
		}
	}
	@media screen and (max-width: 430px){
		#map{
			height: 230px;
			padding: 0 15px;
		}
		.hts{
			position: relative;
			top: 0;
			transform: translateY(0);
			margin: 15px;
			&:after{
				display: none;
			}
			li{
				padding: 15px 15px 15px 60px;
				&:before{
					width: 40px;
					height: 40px;
					left: 10px;
				}
				&:after{
					font-size: 16px;
					left: 24px;
				}
				&.email{
					&:after{
						font-size: 14px;
					}
				}
			}
		}
	}
}
/* main content */
