/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 13, 2018 */
@font-face {
    font-family: 'Exo 2.0 Semi';
    src: url('../fonts/Exo20-SemiBoldItalic.eot');
    src: url('../fonts/Exo20-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Exo20-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/Exo20-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Exo 2.0 Extra';
    src: url('../fonts/Exo20-ExtraBoldItalic.eot');
    src: url('../fonts/Exo20-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Exo20-ExtraBoldItalic.woff2') format('woff2'),
        url('../fonts/Exo20-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Exo 2.0R';
    src: url('../fonts/Exo20-Regular.eot');
    src: url('../fonts/Exo20-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Exo20-Regular.woff2') format('woff2'),
        url('../fonts/Exo20-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Exo 2.0 Extra';
    src: url('../fonts/Exo20-ExtraLightItalic.eot');
    src: url('../fonts/Exo20-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Exo20-ExtraLightItalic.woff2') format('woff2'),
        url('../fonts/Exo20-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Exo 2.0M';
    src: url('../fonts/Exo20-Medium.eot');
    src: url('../fonts/Exo20-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Exo20-Medium.woff2') format('woff2'),
        url('../fonts/Exo20-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Exo 2.0';
    src: url('../fonts/Exo20-ThinItalic.eot');
    src: url('../fonts/Exo20-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Exo20-ThinItalic.woff2') format('woff2'),
        url('../fonts/Exo20-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Exo 2.0';
    src: url('../fonts/Exo20-BlackItalic.eot');
    src: url('../fonts/Exo20-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Exo20-BlackItalic.woff2') format('woff2'),
        url('../fonts/Exo20-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Exo 2.0 Extra';
    src: url('../fonts/Exo20-ExtraBold.eot');
    src: url('../fonts/Exo20-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Exo20-ExtraBold.woff2') format('woff2'),
        url('../fonts/Exo20-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Exo 2.0';
    src: url('../fonts/Exo20-Black.eot');
    src: url('../fonts/Exo20-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Exo20-Black.woff2') format('woff2'),
        url('../fonts/Exo20-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Exo 2.0';
    src: url('../fonts/Exo20-LightItalic.eot');
    src: url('../fonts/Exo20-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Exo20-LightItalic.woff2') format('woff2'),
        url('../fonts/Exo20-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Exo 2.0';
    src: url('../fonts/Exo20-Thin.eot');
    src: url('../fonts/Exo20-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Exo20-Thin.woff2') format('woff2'),
        url('../fonts/Exo20-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Exo 2.0 Extra';
    src: url('../fonts/Exo20-ExtraLight.eot');
    src: url('../fonts/Exo20-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Exo20-ExtraLight.woff2') format('woff2'),
        url('../fonts/Exo20-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Exo 2.0';
    src: url('../fonts/Exo20-MediumItalic.eot');
    src: url('../fonts/Exo20-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Exo20-MediumItalic.woff2') format('woff2'),
        url('../fonts/Exo20-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Exo 2.0';
    src: url('../fonts/Exo20-Italic.eot');
    src: url('../fonts/Exo20-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Exo20-Italic.woff2') format('woff2'),
        url('../fonts/Exo20-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Exo 2.0 Semi';
    src: url('../fonts/Exo20-SemiBold.eot');
    src: url('../fonts/Exo20-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Exo20-SemiBold.woff2') format('woff2'),
        url('../fonts/Exo20-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Exo 2.0';
    src: url('../fonts/Exo20-BoldItalic.eot');
    src: url('../fonts/Exo20-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Exo20-BoldItalic.woff2') format('woff2'),
        url('../fonts/Exo20-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Exo 2.0L';
    src: url('../fonts/Exo20-Light.eot');
    src: url('../fonts/Exo20-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Exo20-Light.woff2') format('woff2'),
        url('../fonts/Exo20-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Exo 2.0B';
    src: url('../fonts/Exo20-Bold.eot');
    src: url('../fonts/Exo20-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Exo20-Bold.woff2') format('woff2'),
        url('../fonts/Exo20-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

$regular: 'Exo 2.0R';
$bold: 'Exo 2.0B';
$light: 'Exo 2.0BL';
$boldSB: 'Exo 2.0 Semi';
$medium: 'Exo 2.0M';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}
@mixin contain{
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center center;
}
@mixin cover{
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center center;
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	outline: none;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

input,textarea, a{
	outline: none;
}

body {
	display: flex;
	height: 100vh;
    min-width: 300px;
	position: relative;
	flex-direction: column;
	font-family: $regular;
	-webkit-text-size-adjust: 100%;
	margin: 0;
	padding-top: 230px;
	font-size: 18px;
    @media screen and (max-width: 990px) {
		padding-top: 80px;
	}
    @media screen and (max-width: 768px) {
		display: block;
		font-size: 16px;
    }
}

h1 {
	font-family: $bold;
	font-size: 42px;
	text-transform: uppercase;
	color: #252525;
	line-height: 1;
	margin: 0 0 40px;
    a {
        font-size: 42px;
        color: #252525;
        &:hover {
            color: #252525;
        }
    }
	@media screen and (max-width: 1200px) {
		font-size: 32px;
        a {
            font-size: 32px;
        }
	}
	@media screen and (max-width: 1024px) {
		font-size: 28px;
        margin-bottom: 30px;
        a {
            font-size: 28px
        }
	}
    @media screen and (max-width: 768px) {
        font-size: 24px;
        margin-bottom: 20px;
        a {
            font-size: 24px;
        }
    }
}
h2 {
	font-family: $bold;
	font-size: 36px;
	color: #252525;
    margin: 0 0 40px;
	a {
        font-size: 36px;
		color: #252525;
        &:hover {
            color: #252525;
        }
	}
	@media screen and (max-width: 1200px) {
		font-size: 30px;
		a {
			font-size: 30px;
		}
	}
	@media screen and (max-width: 1024px) {
		font-size: 24px;
        margin-bottom: 30px;
		a {
			font-size: 24px;
		}
	}
    @media screen and (max-width: 768px) {
        font-size: 22px;
        margin-bottom: 20px;
    }
}
h3 {
	font-family: $bold;
	font-size: 24px;
	color: #252525;
	@media screen and (max-width: 1024px) {
		font-size: 22px;
	}
    @media screen and (max-width: 20px) {
        font-size: 20px;
    }
}
h4 {
	font-family: $bold;
	font-size: 22px;
	color: #252525;
    @media screen and (max-width: 1024px) {
        font-size: 20px;
    }
    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
}
h5 {
	font-family: $bold;
	font-size: 18px;
	color: #252525;
}
h6 {
	font-family: $bold;
	font-size: 16px;
	text-transform: uppercase;
	color: #252525;
}
a {
    color: #282727;
	transition: 300ms;
	text-decoration: none;
	&:focus {
		color: #252525;
		text-decoration: none;
	}
	&:hover {
		text-decoration: none;
        color: #252525;
	}
}
textarea, 
button, 
select, 
input[type="text"], 
input[type="email"], 
input[type="search"],
input[type="password"],
input[type="file"],
input[type="submit"] {
	border-radius: 0px;
	-webkit-appearance: none;
}

textarea, 
input[type="text"], 
input[type="email"], 
input[type="search"], 
input[type="password"] {
	width: 100%;
	background-color: #fff;
	font-family: $regular;
	font-size: 16px;
	padding: 11px 13px;
	margin-bottom: 8px;
	box-shadow: none;
	border: 1px solid transparent;	
	outline: none;
	color: #606060;	
	&:focus{
		border: 1px solid #ff0000;
	}
	@media screen and (max-width: 540px){
		padding: 8px 10px;
	}
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}


// .main {
// 	.container {
// 		padding-top: 50px;
// 		padding-bottom: 50px;
// 		@media screen and (max-width: 1024px) {
// 			padding-top: 25px;
// 			padding-bottom: 25px;
// 		}
// 		@media screen and (max-width: 768px) {
// 			padding-top: 15px;
// 			padding-bottom: 15px;
// 		}
// 	}
// }

.main, .page {
	flex: 1 0 auto;
}

.container {
    width: 1170px;
    padding: 0 15px;
	margin: 0 auto;
	@media screen and (max-width: 1169px){
		width: 100%;
	}
}

.close {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 8px;
    top: 10px;
    opacity: 1;
    outline: none;
    &:before, &:after {
        content: '';
        width: 25px;
        height: 2px;
        background-color: #faa643;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -1px auto 0;
        transition: 300ms;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        &:before {
            transform: rotate(135deg);
        }
        &:after {
            transform: rotate(45deg);
        }
    }
}
.btn {
	display: table;
	width: auto!important;
	font-family: $bold;
	font-size: 18px;
	color: #fff;
    text-align: center;
	cursor: pointer;
	border-radius: 0px;
	background-color: #ff0000;
	padding: 10px 40px;
	border: none;
    line-height: 1;
	transition: 300ms;
	&:hover, &.active {
		background-color: #faa643;
		color: #fff;
	}
	@media screen and (max-width: 540px){
		font-size: 16px;
	}	
}

.title-block{
	position: relative;
	padding-bottom: 25px;
	display: table;
	&:after{
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		width: 80px;
		border-bottom: 3px solid #faa643;
	}
}

.title-lines{
	display: table;
	margin: 0 auto;
	position: relative;
	&:before{
        content: '';
        position: absolute;
		width: 37vw;;
		left: 0;
        top: 27px;
        height: 1px;
        border-top: 1px solid #bdbdbd;
    }
    &:after{
        content: '';
		position: absolute;
		right: 0;
        width: 37vw;
        top: 27px;
        height: 1px;
        border-top: 1px solid #bdbdbd;
    }
}
.title-block-2{
	position: relative;
	padding-bottom: 25px;
	padding: 0 25px 25px 25px;
	display: table;
	text-align: center;
	background-color: #fff;
	z-index: 1;
	text-transform: uppercase;
	&:after{
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		width: 80px;
		margin: 0 auto;
		border-bottom: 3px solid #faa643;
	}
}
.title-block-3{
	position: relative;
	font-size: 26px;
	text-transform: uppercase;
	display: table;
	margin-bottom: 25px;
	span{
		position: absolute;
		font-size: 102.55px;
		color: #e9e9e9;
		top: -60px;
		left: -70px;
		font-family: $bold;
		z-index: -1;
	}
	@media screen and (max-width: 1300px){
		span{
			left: 0;
			top: -100px;
		}
	}
	@media screen and (max-width: 990px){
		font-size: 24px;
		span{
			font-size: 90px;
		}
	}
	@media screen and (max-width: 768px){
		font-size: 22px;
		span{
			font-size: 80px;
			top: -45px;
		}
	}
	@media screen and (max-width: 540px){
		font-size: 20px;
		span{
			font-size: 70px;
		}
	}
}

.check--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		padding-left: 25px;
		margin-bottom: 5px;
		&:before {
			content: '\f00c';
			position: absolute;
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #000;
			left: 0;
			top: 0px;
		}
	}
}

.col--address,
.col--phone,
.col--email {
	position: relative;
	padding-left: 18px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 14px;
		color: #faa643;
		position: absolute;
		top: 3px;
		left: 0;
	}
}
.col--address:after {
	content: '\f041';
}
.col--phone:after {
	content: '\f095';
}
.col--email:after {
	content: '\f0e0';
	font-size: 12px;
	top: 8px;
}
.col--email:hover{
	color: #faa643;
}
/* header */
header {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 9;
	.container{
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		z-index: 1;
	}
	.top{
		background: linear-gradient(to left, #1c1c1c 0, #3f3f3f 50%, #1c1c1c 100%);
		.left{
			display: flex;
			color: #fff;
			p{
				font-size: 14px;
				margin-right: 33px;
			}
		}
		.right{
			display: flex;
			align-items: center;
			a{
				color: #fff;
			}
			.col--email{
				margin-right: 15px;
				padding-right: 15px;
				position: relative;
				&:before{
					content: "\f107";
					font-family: 'FontAwesome';
					position: absolute;
					font-size: 11px;
					right: 0px;
					top: 9px;
					pointer-events: auto;
					color: #fff;
					transition: 300ms;
				}
				a{
					font-size: 14px;
					&:hover{
						color: #faa643;
					}
				}
				ul{
					display: none;
					position: absolute;
					right: 0;
					left: -5px;
					background-color: #faa643;
					box-shadow: 0 0 5px rgba(0,0,0,0.5);
					@include ul-default;
					padding: 5px 0 10px 20px;
					li{
						a{
							color: #fff;
							font-size: 14px;
						}
					}
				}
				&.open{
					&:before{
						transform: rotate(180deg);
					}
				}
			}
			.call-btn{
				font-size: 18px;
				font-family: $medium;
				&:hover{
					background-color: #fff;
					color: #ff0000;
				}
			}
		}
	}
	.logo{
		width: 300px;
		height: 100px;
		img{
			width: 100%;
			height: 100%;
			object-fit: contain;
			object-position: center center;
		}
	}
	.middle{
		background: url(../img/header-bg.png) no-repeat center center;
		background-size: cover;
		position: relative;
		padding: 13px 0;
		&:after{
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background: linear-gradient(to left, rgba(0,0,0,0.7) 0, transparent 50%, rgba(0,0,0,0.7) 100%);
		}
		.time{
			color: #fff;
			h5{
				font-size: 18px;
				text-transform: uppercase;
				color: #fff;
			}
		}
		.phones{
			li{
				margin-bottom: 17px;
				display: flex;
				justify-content: flex-end;
				h5{
					color: #fff;
					font-family: $boldSB;
				}
				.phone-num{
					margin-left: 5px;
					a{
						color: #fff;
						pointer-events: none;
					}
					.show-num{
						color: #faa643;
						font-size: 16px;
						display: table;
						pointer-events: auto;
						margin-left: auto;
						&.hide{
							opacity: 0;
						}
					}
				}
			}
		}
	}
	.bottom{
		background-color: #faa643;
		.nav{
			display: flex;
			@include ul-default;
			margin: 0 -13px;
			>li{
				>a{
					padding: 14px 13px;
					display: block;
					color: #fff;
					font-family: $medium;
					&:hover{
						background-color: #ea7f00;
					}
				}
				&.active{
					a{
						background-color: #ea7f00;
					}
				}
			}
		}
		.right{
			display: flex;
			align-items: center;
			.search{
				margin-right: 15px;
				position: relative;
				.search--btn{
					position: relative;
					display: block;
					font-size: 18px;
					font-family: 'FontAwesome';
					color: #fff;
					&:after{
						content: "\f002";
					}
				}
				form{
					width: 0;
					position: absolute;
					transition: 300ms;
					right: 35px;
					top: -14px;
					pointer-events: none;
					opacity: 0;
					input[type="search"]{
						box-shadow: 0 0 5px rgba(0,0,0,0.5);
						padding: 12px 40px 12px 20px;
					}
					.close-btn{
						position: absolute;
						top: 10px;
						right: 15px;
						font-family: 'FontAwesome';
						font-size: 16px;
						color: #606060;
						cursor: pointer;
						&:after{
							content: '\f00d';
							padding: 5px;
						}
					}
				}
				&.open{
					overflow: visible;
					form{
						width: 300px;
						transition: 300ms;
						pointer-events: auto;
						opacity: 1;
					}
				}
			}
			.social{
				display: flex;
				@include ul-default;
				margin-right: 5px;
				li{
					a{
						display: flex;
						align-items: center;
						justify-content: center;
						width: 50px;
						height: 50px;
						i{
							color: #fff;
							font-size: 20px;
						}
						&:hover{
							background-color: #ea7f00;
						}
					}
				}
			}
			.langs{
				position: relative;
				span{
					color: #fff;
					font-family: $medium;
					padding-right: 20px;
					cursor: pointer;
						&:after{
							content: "\f107";
							font-family: 'FontAwesome';
							position: absolute;
							font-size: 11px;
							right: 8px;
							top: 8px;
							pointer-events: auto;
							color: #fff;
							transition: 300ms;
						}
					}
				ul{
					@include ul-default;
					display: none;
					position: absolute;
					right: 0;
					left: -5px;
					background-color: #faa643;
					box-shadow: 0 0 5px rgba(0,0,0,0.5);
					padding: 5px;
					li{
						a{
							color: #fff;
							font-family: $medium;
						}
					}
				}
				&.open{
					span{
						&:after{
							transform: rotate(180deg);
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: 990px) {
		height: 80px;
		background: url(../img/header-bg.png) no-repeat center center;
		background-size: cover;
		z-index: 9;
		.nav--btn {
			width: 35px;
			height: 30px;
			position: absolute;
			left: 10px;
			top: 50%;
			transform: translateY(-50%);
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #fff;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
		.phone--btn{
			position: absolute;
			top: 35px;
			right: 10px;
			color: #fff;
			font-family: 'FontAwesome';
			font-size: 16px;
			&:after{
				content: '\f095';
			}
			&.open{
				&:after{
					content: '\f00d';
				}
			}
		}
		.top{
			.right{
				.call-btn{
					position: fixed;
					bottom: 20px;
					right: 20px;
					padding: 15px;
					font-size: 0;
					&:after{
						content: '\f095';
						position: absolute;
						font-family: 'FontAwesome';
						top: 50%;
						transform: translateY(-50%);
						right: 0;
						left: 0;
						bottom: 0;
						color: #fff;
						font-size: 14px;
					}
				}
				.col--email{
					display: none;
				}
			}
			.left{
				display: none;
			}
		}
		.logo{
			width: 100px;
			height: 70px;
			position: fixed;
			top: 5px;
			right: 0;
			left: 0;
			margin: 0 auto;
			z-index: 2;
		}
		.middle{
			padding: 0;
			position: fixed;
			top: 80px;
			right: 0;
			left: 0;
			.time{
				display: none;
			}
			.phones{
				display: none;
			}
		}
		.bottom {
			background: none;
			.nav{
				display: none;
				position: fixed;
				top: 80px;
				right: 0;
				left: 0;
				background-color: #faa643;
				padding: 0 15px;
				>li{
					>a{
						padding: 9px;
					}
				}
			}
			.right{
				.langs{
					position: absolute;
					top: 30px;
					left: 55px;
				}
				.social{
					display: none;
				}
				.search{
					position: absolute;
					top: 33px;
					right: 0;
					left: 0;
					.search--btn{
						position: absolute;
						right: 35px;
					}
					form{
						position: fixed;
						right: 0;
						left: 100%;
						width: auto;
						top: 80px;
					}
					&.open{
						form{
							width: auto;
							left: 0;
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: 450px) {
		.container{
			display: block;
		}
		.middle{
			.time{
				text-align: center;
				margin: 15px 0;
			}
			.phones{
				margin: 10px 0;
				li{
					justify-content: center;
				}
			}
		}
	}
}
/* header */