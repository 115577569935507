/* footer */
footer{
    .footer-top{
        background-color: #242424;
        padding: 55px 0 85px 0;
        .container{
            display: flex;
            justify-content: space-between;
        }
        .footer-logo{
            .logo{
                width: 270px;
                height: 90px;
                display: block;
                margin-bottom: 15px;
                >img{
                    @include contain;
                }
            }
            .social{
                @include ul-default;
                display: flex;
                justify-content: center;
                li{
                    a{
                        width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100%;
                        margin: 0 5px;
                        i{
                            color: #fff;
                            font-size: 18px;
                        }
                        &:hover{
                            background-color: #faa643;
                        }
                    }
                }
            }
        }
        h3{
            color: #fff;
            font-size: 18px;
            text-transform: uppercase;
            margin-bottom: 25px;
        }
        .footer-col{
            color: #fff;
            p{
                font-size: 16px;
            }
            a{
                color: #fff;
                font-size: 16px;
            }
            ul{
                @include ul-default;
                li{
                    margin-bottom: 12px;
                    a{
                        color: #fff;
                        display: block;
                        font-size: 16px;
                        &:hover{
                            color: #faa643;
                        }
                    }
                    &.active{
                        a{
                            color: #faa643;
                        }
                    }
                }
            } 
            .col--phone{
                margin: 15px 0;
                &:after{
                    top: 50%;
                }
                ul li a{
                    pointer-events: none;
                    .show-num{
                        color: #faa643;
                        pointer-events: auto;
                    }
                }
            }
            .col--email{
                &:hover{
                    color: #faa643;
                }
            }
        }
        .footer-form{
            form{
                width: 213px;
                input[type="text"],
                input[type="email"]{
                    width: 100%;
                    border: 1px solid #fff;
                    padding: 9px 14px;
                    background-color: transparent;
                    margin-bottom: 12px;
                    color: #fff;
                    &::placeholder{
                        color: #fff;
                    }
                }
                .btn{
                    background-color: #faa643;
                    font-family: $boldSB;
                    font-size: 16px;
                    padding: 15px 45px;
                    &:hover{
                        background-color: #ff0000;
                    }
                }
            }
        }
        @media screen and (max-width: 950px){
            padding: 45px 0;
            .container{
                flex-wrap: wrap;
            }
            .footer-col{
                margin: 10px;
            }
        }
        @media screen and (max-width: 768px){
            padding: 25px 0;
            h3{
                margin-bottom: 15px;
                font-size: 16px;
            }
        }
        @media screen and (max-width: 450px){
            .footer-logo{
                display: table;
                margin: 0 auto;
                .logo{
                    height: 70px;
                    margin: 0 auto;
                }
            }
            .footer-col{
                ul{
                    li{
                        margin-bottom: 7px;
                    }
                }
                .col--phone{
                    margin: 10px 0;
                }
            }
            .footer-form{
                margin-top: 10px;
                form{
                    .btn{
                        padding: 10px 30px;
                    }
                }
            }
        }
    }
    .footer-bottom{
        padding: 23px 0;
        background-color: #3f3f3f;
        .container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                font-size: 16px;
                color: #fff;
            }
            .artmedia{
                position: relative;
                padding-right: 130px;
                color: #fff;
                font-size: 16px;
                &:hover{
                    color: #faa643;
                }
                >img{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                }
            }
        }
        @media screen and (max-width: 768px){
            .container{
                display: block;
                text-align: center;
                p{
                    margin-bottom: 10px;
                    font-size: 14px;
                }
                .artmedia{
                    font-size: 14px;
                }
            }
        }
    }
}

/* footer */